import React from 'react';
import { BrowserRouter, Link } from 'react-router-dom';

import Image from '@/assets/images/robot.png';
import IntlMessages from '@/components/IntlMessages';

import FiveZeroZeroStyle from './styles';

export default function() {
  return (
    <FiveZeroZeroStyle className="page500Container">
      <div className="page500Content">
        <h1>
          <IntlMessages id="page500.title" />
        </h1>
        <h3>
          <IntlMessages id="page500.subTitle" />
        </h3>
        <p>
          <IntlMessages id="page500.description" />
        </p>
        <BrowserRouter>
          <Link to="/">
            <button type="button">
              <IntlMessages id="page500.backButton" />
            </button>
          </Link>
        </BrowserRouter>
      </div>

      <div className="page500Artwork">
        <img alt="#" src={Image} />
      </div>
    </FiveZeroZeroStyle>
  );
}

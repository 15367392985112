import Uslang from './en-US';
import Eslang from './es-ES';
import Brlang from './pt-BR';

const AppLocale = {
  us: Uslang,
  es: Eslang,
  br: Brlang,
};

export default AppLocale;

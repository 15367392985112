import 'react-image-lightbox/style.css';
import React from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import Loader from '@/components/Loader';
import Routes from '@/routes/Routes';
import GlobalStyles from '@/styles/globalStyle';

import AppProvider from './AppProvider';
import { store, persistor } from './store';

import '@/config/reactotron.config';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <AppProvider>
          <>
            <GlobalStyles />
            <Routes />
          </>
        </AppProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

import styled from 'styled-components';
import { palette, size } from 'styled-theme';

import { transition, borderRadius } from '@/helpers/styleUtil';

const FiveZeroZeroStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: ${size('screen_sm_max')}) {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .page500Content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    @media only screen and (max-width: ${size('screen_sm_max')}) {
      order: 2;
      margin-top: 20px;
      align-items: center;
      text-align: center;
    }

    h1 {
      font-size: ${size('font_size_10xl')};
      font-weight: 700;
      color: ${palette('text', 0)};
      line-height: 1;
      margin: 0 0 25px;
    }

    h3 {
      font-size: ${size('font_size_2xl')};
      font-weight: 400;
      color: ${palette('text', 1)};
      margin: 0 0 10px;
      line-height: 1.2;
    }

    p {
      font-size: ${size('font_size_base')};
      font-weight: 400;
      color: ${palette('text', 2)};
      margin: 0 0 10px;
    }

    button {
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 0;
      font-weight: 500;
      text-align: center;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      border: 0;
      white-space: nowrap;
      flex-shrink: 0;
      line-height: 1.5;
      padding: 0 30px;
      font-size: ${size('font_size_base')};
      height: 36px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      color: ${palette('white', 0)};
      background-color: ${palette('primary_color', 0)};
      ${transition()};
      ${borderRadius('18px')};

      a {
        width: 100%;
        height: 100%;
        color: ${palette('white', 0)};
        text-decoration: none;
      }

      &:hover {
        background-color: ${palette('primary', 2)};

        a {
          text-decoration: none;
        }
      }

      &:focus {
        outline: 0;
        box-shadow: none;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .page500Artwork {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: inherit;
    height: 500px;

    @media only screen and (max-width: ${size('screen_sm_max')}) {
      margin-left: 0;
      margin-right: inherit;
    }

    img {
      max-height: 100%;
    }
  }
`;

export default FiveZeroZeroStyle;

import darkTheme from '@/styles/theme/dark';
import defaultTheme from '@/styles/theme/default';
import lightTheme from '@/styles/theme/light';

const themes = {
  darkTheme,
  lightTheme,
  defaultTheme,
};

export const themeConfig = {
  topbar: 'defaultTheme',
  sidebar: 'defaultTheme',
  layout: 'defaultTheme',
  theme: 'defaultTheme',
};

export default themes;

import React from 'react';
import Loadable from 'react-loadable';

import Loader from '@/components/Loader';

const BlankLayout = Loadable({
  loader: () => import('./blank.layout'),
  render(loaded, props) {
      const Component = loaded.default;
      return <Component {...props} />;
  },
  loading: Loader,
});

const UserLayout = Loadable({
  loader: () => import('./user.layout'),
  render(loaded, props) {
      const Component = loaded.default;
      return <Component {...props} />;
  },
  loading: Loader,
});

const AppLayout = Loadable({
  loader: () => import('./app.layout'),
  render(loaded, props) {
      const Component = loaded.default;
      return <Component {...props} />;
  },
  loading: Loader,
});

export { BlankLayout, UserLayout, AppLayout };

import { stripUnit } from 'polished';

const sum = (...args) => {
  const values = args.map(value => stripUnit(value) || 0);
  const unit = args[0].split(values[0])[1];

  return `${values.reduce((a,b) => a + b, 0)}${unit}`;
};

const subtract = (string, subString) => {
  const number = stripUnit(string) || 0;
  const subNumber = stripUnit(subString) || 0;
  const unit = string.split(number)[1];

  return `${number - subNumber}${unit}`;
};

const multiply = (multiplier, string) => {
  const number = stripUnit(string) || 0;
  const unit = string.split(number)[1];

  return `${number * multiplier}${unit}`;
};

const divide = (denominator, string) => {
  const number = stripUnit(string) || 0;
  const unit = string.split(number)[1];

  return `${number / denominator}${unit}`;
};

const round = (criteria, string) => {
  const number = stripUnit(string) || 0;
  const unit = string.split(number)[1];

  return `${Math[criteria](number)}${unit}`;
};

export { sum, subtract, multiply, divide, round };
